<header>
  <div class="hours-calc-info">
    <div
      class="hours-calc-info-wrap"
      [ngClass]="{ active: currentIndex === 1 }"
      (click)="openHourseList(['admin', 'billablehour'])"
    >
      <div class="hours-calc-info-img">
        <svg-icon name="billable-icon" class="icon"></svg-icon>
      </div>
      <div class="hours-calc-info-content">
        <span>Billable Hours</span>
        <h3>{{ billableHours || 0 }}</h3>
      </div>
    </div>

    <div
      class="hours-calc-info-wrap"
      [ngClass]="{ active: currentIndex === 2 }"
      (click)="openHourseList(['admin', 'hjhours'])"
    >
      <div class="hours-calc-info-img">
        <svg-icon name="HJHours-icon" class="icon"></svg-icon>
      </div>
      <div class="hours-calc-info-content">
        <span>Non-Billable Hours</span>
        <h3>{{ hJHours || 0 }}</h3>
      </div>
    </div>

    <div
      class="hours-calc-info-wrap"
      [ngClass]="{ active: currentIndex === 3 }"
      (click)="openHourseList(['admin', 'temptimehour'])"
    >
      <div class="hours-calc-info-img">
        <svg-icon name="HJHours-icon" class="icon"></svg-icon>
      </div>
      <div class="hours-calc-info-content">
        <span>Temp Time Hours</span>
        <h3>{{ tempTimeHrs || 0 }}</h3>
      </div>
    </div>

    <div
    class="hours-calc-info-wrap"
    [ngClass]="{ active: currentIndex === 4 }"
    style="cursor: auto;"
  >
    <div class="hours-calc-info-img">
      <svg-icon name="HJHours-icon" class="icon"></svg-icon>
    </div>
    <div class="hours-calc-info-content">
      <span>Today's Utilisation</span>
      <h3>{{ utilisationData() | number: '1.0-2' }}%</h3>

    </div>
  </div>
  </div>

  <div class="right-header">
    <div ngbDropdown class="add">
      <button type="button" class="btn add-manualtime-btn" id="dropdownBasic2" ngbDropdownToggle>
        <svg-icon name="add-icon" class="icon"></svg-icon>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button
          ngbDropdownItem
          class="btn"
          *ngIf="localPermission.canView"
          type="button"
          (click)="openManualRequestModal()"
        >
          Add Time Entry
        </button>
        <button ngbDropdownItem class="btn" (click)="openBudgetModal()" type="button">Edit Matter Budget</button>
      </div>
    </div>
    <div>
      <div class="timer d-flex align-items-center" *ngIf="!isAdmin && timesheetPermission?.canView">
        <button type="button" class="timer-btn btn custom-tooltip-action">
          <app-timer-buttons
            [isDashboardStart]="true"
            (validationErrros)="setValidation($event)"
            (isLoading)="isLoadingScreen($event)"
          ></app-timer-buttons>
          <div class="custom-tooltip" [ngClass]="{ 'custom-tooltip-timer-running': isTimerRunning }">
            <div *ngIf="!isTimerRunning; else runningTimerDetails">
              <h3>Start/Stop Timer</h3>
            </div>
            <ng-template #runningTimerDetails>
              <div class="running-timer-details">
                <div class="col-sm-12 col-md-12 client-name-block">
                  <p
                    class="text-with-ellipsis m-width-250px"
                    [title]="currentTimer?.clientName || 'Unassigned Matter/Category'"
                  >
                    {{ currentTimer?.clientName || 'Unassigned Matter/Category' }}
                  </p>
                  <p
                    class="text-with-ellipsis m-width-250px"
                    [title]="currentTimer?.matterName"
                    *ngIf="currentTimer?.matterName"
                  >
                    {{ currentTimer?.matterName }}
                  </p>
                </div>
              </div>
            </ng-template>
          </div>
        </button>
        <button type="button" class="timer-edit-btn btn custom-tooltip-action" (click)="openTimerPopUpModal()">
          <svg-icon name="clock-white-ico" class="icon" style="color: #2b3674"></svg-icon>
          <div class="custom-tooltip">
            <h3>Open Timer Pop-up</h3>
          </div>
        </button>
      </div>
      <div class="header-edited-timer-error">
        <mat-error *ngIf="isEditedNewTimeInvalid">{{ validationMessages.timeSheetTimeInvalidFormat }} </mat-error>
        <mat-error *ngIf="isEditedNewTimeExceeded">Please enter valid time</mat-error>
      </div>
    </div>
  </div>
</header>
<div class="loader-animation" *ngIf="isLoading">
  <svg-icon name="loader-animation" style="color: #8f9bba" class="input-icon"></svg-icon>
</div>
